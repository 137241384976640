import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TopbarService } from '../topbar.service';
import { Sidebar } from 'primeng/sidebar';
import { PackageType } from '@kodit/core/data-api';
import { AuthService } from '@kodit/core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'kodit-podesavanja-sidebar',
  templateUrl: './podesavanja-sidebar.component.html',
  styleUrls: ['./podesavanja-sidebar.component.scss'],
})
export class PodesavanjaSidebarComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  showSidebar: boolean;
  clientPackageId: string;
  el: any;
  showcloseIcon = false;

  /** Props */
  shouldDisplayKompanijaCard: boolean;
  shouldDisplayKorisnickiNaloziCard: boolean;
  shouldDisplayRacunCard: boolean;
  shouldDisplayListeCard: boolean;
  shouldDisplayOznakeCard: boolean;
  shouldDisplayPocetnaStanjaCard: boolean;
  shouldDisplayFiskalnaCard: boolean;
  shouldDisplayArhivskaCard: boolean;

  @ViewChild('PodesavanjaSidebar') sidebarRef: Sidebar;

  constructor(
    private service: TopbarService,
    private _authService: AuthService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._loadDisplays();
    this._subs.add(
      this.service.getShowPodesavanjaSidebar.subscribe((show) => {
        this.showSidebar = show;
      })
    );
  }

  private _loadDisplays() {
    //Kompanija
    this.shouldDisplayKompanijaCard = true;
    //Korisnicki nalozi
    this.shouldDisplayKorisnickiNaloziCard = true;
    //Racuni
    this.shouldDisplayRacunCard = this._authService.hasPackage([
      PackageType[PackageType.FAKTURISANJE],
      PackageType[PackageType.VELEPRODAJA],
    ]);
    //Registri
    this.shouldDisplayListeCard = this._authService.hasPackage([
      PackageType[PackageType.FAKTURISANJE],
      PackageType[PackageType.VELEPRODAJA],
      PackageType[PackageType.MALOPRODAJA],
    ]);
    //Oznake
    this.shouldDisplayOznakeCard = this._authService.hasPackage([
      PackageType[PackageType.FAKTURISANJE],
      PackageType[PackageType.VELEPRODAJA],
      PackageType[PackageType.MALOPRODAJA],
    ]);
    //Pocetna stanja
    this.shouldDisplayPocetnaStanjaCard = this._authService.hasPackage([
      PackageType[PackageType.FAKTURISANJE],
      PackageType[PackageType.VELEPRODAJA],
      PackageType[PackageType.MALOPRODAJA],
    ]);
    //Fiskalna
    this.shouldDisplayFiskalnaCard = this._authService.hasPackage(
      PackageType[PackageType.MALOPRODAJA]
    );
    //Arhiva
    this.shouldDisplayArhivskaCard = this._authService.hasPackage(
      PackageType[PackageType.ARHIVSKA_KNJIGA_I_DELOVODNIK]
    );
  }

  redirectToLink(cardLink: string) {
    this.onHide();
    this._router.navigate(['/podesavanja', cardLink]);
  }

  onHide() {
    //this.service.hideNotifikacijeSidebar();
    this._destroy();
  }

  onShow() {
    this.service.hideNotifikacijeSidebar();
    this.service.hideProfileSidebar();
  }

  private _destroy() {
    if (this.sidebarRef) {
      this.service.hidePodesavanjaSidebar();
      this.sidebarRef.destroyModal();
    }
  }

  ngOnDestroy() {
    this._destroy();
    // this.service.hidePodesavanjaSidebar();
    // this._subs.unsubscribe();
  }
}
