<div class="kodit-card-tab">
  <p-tabView
    [(activeIndex)]="activeIndex"
    (activeIndexChange)="handleIndexChange($event)"
  >
    <p-tabPanel header="Pojedinačne"></p-tabPanel>
    <p-tabPanel header="Zbirne"></p-tabPanel>
  </p-tabView>

  <div [ngSwitch]="activeIndex">
    <div *ngSwitchCase="0">
      <kodit-pdv-evidencija-pojedinacna-page></kodit-pdv-evidencija-pojedinacna-page>
    </div>
    <div *ngSwitchCase="1">
      <kodit-pdv-evidencija-zbirna-page></kodit-pdv-evidencija-zbirna-page>
    </div>
  </div>
</div>
