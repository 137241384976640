import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Sidebar } from 'primeng/sidebar';
import { Subscription } from 'rxjs';
import { TopbarService } from '../topbar.service';
import {
  INotifikacijaKorisnikaDto,
  NotifikacijeClient,
  ReadAllNotificationsComand,
} from '@kodit/core/data-api';
import { NotificationService } from '@kodit/core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'kodit-notifikacije-sidebar',
  templateUrl: './notifikacije-sidebar.component.html',
  styleUrls: ['./notifikacije-sidebar.component.scss'],
})
export class NotifikacijeSidebarComponent
  implements OnInit, OnDestroy, OnChanges {
  private _subs: Subscription = new Subscription();
  showSidebar: boolean;
  clientPackageId: string;
  el: any;
  showcloseIcon = false;
  notifikacijeKorisnika: INotifikacijaKorisnikaDto[] = [];

  @ViewChild('NotifikacijeSidebar') sidebarRef: Sidebar;

  constructor(
    private service: TopbarService,
    private _notificationService: NotificationService,
    private _client: NotifikacijeClient,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this.service.getShowNotifikacijeSidebar.subscribe((show) => {
        this.showSidebar = show;
      })
    );
  }

  onHide() {
    // hide podesavanja sidebar
    //this.service.hidePodesavanjaSidebar();
    this._destroy();
  }

  onShow() {
    // hide podesavanja sidebar
    this._loadNotifications();
    //this.prepareUserInfo();
    this._notificationsSub();


    this.service.hidePodesavanjaSidebar();
    this.service.hideProfileSidebar();
  }

  ngOnChanges(): void {
    //this._loadNotifications();
    this.handleOznaciKaoProcitano();
  }

  private _destroy() {
    if (this.sidebarRef) {
      this.service.hideNotifikacijeSidebar();
      this.sidebarRef.destroyModal();
    }
  }

  handleOznaciKaoProcitano() {
    this._subs.add(
      this._client
        .readAllNotificationsKorisnika(
          new ReadAllNotificationsComand({
            notifikacijeIds: this.notifikacijeKorisnika
              .map((x) => x.id)
              .slice(0, 20),
          })
        )
        .subscribe((res) => {
          if (res.succeeded) {
            location.reload();
          }
        })
    );
  }

  gotToNotifikacije() {
    this._router.navigate(['/notifikacije']);
    this.service.hideNotifikacijeSidebar();
  }

  private _loadNotifications() {
    this._subs.add(
      this._client.getNotifikacijaKorisnikaList(true).subscribe((res) => {
        this._notificationService.addTopbarNotifications(
          res.data.notifikacijaKorisnikaDtoList
        );
      })
    );
  }

  private _notificationsSub() {
    this._subs.add(
      this._notificationService.getTopbarNotifications.subscribe((res) => {
        this.notifikacijeKorisnika = res;
        console.log(this.notifikacijeKorisnika);
      })
    );
  }

  ngOnDestroy() {
    this._destroy();
    this.service.hideNotifikacijeSidebar();
    this._subs.unsubscribe();
  }
}
