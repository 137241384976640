import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Type,
} from '@angular/core';
import {
  NotifikacijaKorisnikaDto,
  NotifikacijeClient,
  ReadAllNotificationsComand,
} from '@kodit/core/data-api';
import {ActionClass, ActionType, SelectMode, TableConfig} from '@kodit/core/shared-ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-notifikacije-table',
  templateUrl: './notifikacije-table.component.html',
  styleUrls: ['./notifikacije-table.component.scss'],
})
export class NotifikacijeTableComponent
  implements OnInit, OnChanges, OnDestroy {
  private _subs: Subscription = new Subscription();

  //** Configs */
  tableConfig!: TableConfig;

  //** I/0 */
  @Input() selectbuttonBool: boolean = false;

  //** Props */
  tableItems: NotifikacijaKorisnikaDto[] = [];

  constructor(private _client: NotifikacijeClient) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this._loadNotification();
    this.setTableConfig();
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      selectMode: this.selectbuttonBool ? SelectMode.MULTI : undefined,
      tableHeader: 'Obaveštenja',
      columns: [
        {
          field: 'iconDto',
          type: 'icon',
        },
        {
          field: 'tekst',
          header: 'Naslov',
          type: 'text',
        },
        {
          field: 'naslov',
          header: 'Vrsta',
          type: 'text',
        },
      ],
      headerActions: [
        {
          isVisible: this.selectbuttonBool,
          type: ActionType.MULTI_CUSTOM,
          label: 'Označi kao pročitano',
          actionClass: ActionClass.LINK,
          callback: (result: NotifikacijaKorisnikaDto[]) => {
            this._handleProcitajSve(result.map((x) => x.id!));
          },
        },
      ],
      // advancedFilter: {
      //   component: Type,
      //   data: [],
      //   availableFilters: [],
      // },
    });
  }

  private _loadNotification() {
    this._subs.add(
      this._client
        .getNotifikacijaKorisnikaList(this.selectbuttonBool)
        .subscribe((res) => {
          this.tableItems = res.data?.notifikacijaKorisnikaDtoList!;
        })
    );
  }

  private _handleProcitajSve(notificationIds: string[]) {
    this._subs.add(
      this._client
        .readAllNotificationsKorisnika(
          new ReadAllNotificationsComand({
            notifikacijeIds: notificationIds,
          })
        )
        .subscribe((res) => {
          if (res.succeeded) {
            window.location.reload();
          }
        })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
