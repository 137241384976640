import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IReferentniFiskalniRacunDto } from '@kodit/core/data-api';
import { FormHelper } from '@kodit/core/form-definitions';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { FiskalniRacunService } from '../../fiskalni-racun.service';
import { AlertService } from '@kodit/core/services';

@Component({
  selector: 'kodit-referentni-racun-fiskalnog-racuna-dialog',
  templateUrl: './referentni-racun-fiskalnog-racuna-dialog.component.html',
  styleUrls: ['./referentni-racun-fiskalnog-racuna-dialog.component.scss'],
})
export class ReferentniRacunFiskalnogRacunaDialogComponent
  implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Form */
  form!: FormGroupTypeSafe<IReferentniFiskalniRacunDto>;

  /** Props */
  placeholder: string = 'xxxxxxxx-xxxxxxxx-00';

  constructor(
    private _fiskalniService: FiskalniRacunService,
    private _dialogRef: DynamicDialogRef,
    private _fb: FormBuilderTypeSafe,
    private _alertService: AlertService,
    private _formHelper: FormHelper
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group<IReferentniFiskalniRacunDto>({
      id: new FormControl(null, {
        validators: [Validators.required],
      }),
      referentDocumentNumber: new FormControl(null, {
        validators: [Validators.required],
      }),
      referentDocumentDT: new FormControl(null, {
        validators: [Validators.required],
      }),
      jeStariRacun: new FormControl(false, {
        validators: [Validators.required],
      }),
      hasStariReferentniRacun: new FormControl(false),
    });

    // patchujemo vrednost ukoliko je edit
    this._patchValueOnEdit();

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._onSave();
      })
    );

    this._subs.add(
      this.form.controls.jeStariRacun?.valueChanges.subscribe((value) => {
        this._fiskalniService.resetReferentniRacun();
        this._resetNumberAndDt();
      })
    );
  }

  handleIzaberiReferentniRacun(event: IReferentniFiskalniRacunDto) {
    this.form.patchValue({
      hasStariReferentniRacun: event.hasStariReferentniRacun,
      id: event.id,
      jeStariRacun: false,
      referentDocumentDT: event.referentDocumentDT,
      referentDocumentNumber: event.referentDocumentNumber,
    });
  }

  private _patchValueOnEdit() {
    const referentniFiskalniRacunDto = this._fiskalniService.getFormObject
      .controls.referentniFiskalniRacunDto?.value;

    this.form.patchValue({
      referentDocumentDT: referentniFiskalniRacunDto?.referentDocumentDT,
      referentDocumentNumber:
        referentniFiskalniRacunDto?.referentDocumentNumber,
      id: referentniFiskalniRacunDto?.id,
      jeStariRacun: referentniFiskalniRacunDto?.jeStariRacun ?? false,
      hasStariReferentniRacun:
        referentniFiskalniRacunDto?.hasStariReferentniRacun,
    });
  }

  private _onSave() {
    this._setValidators();

    // notifikujemo autocomplete da je saveFormClicked
    this._fiskalniService.setFormSaveClicked = true;

    if (this.form.invalid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    if (
      this.form.value.jeStariRacun &&
      this.form.value.referentDocumentNumber &&
      !this._isReferentDocumentNumberValid()
    ) {
      this._alertService.addWarnMsg("Broj referentnog računa mora biti u formatu: " + this.placeholder);
      return;
    }

    this._fiskalniService.setReferentniRacun(this.form.value);
    this._dialogRef.close(true);
  }

  private _setValidators() {
    if (this.form.controls.jeStariRacun?.value) {
      this.form.controls.id?.clearValidators();
      this.form.controls.id?.updateValueAndValidity();
    } else {
      this.form.controls.id?.setValidators([Validators.required]);
      this.form.controls.id?.updateValueAndValidity();
    }
  }

  private _resetNumberAndDt() {
    this.form.controls.referentDocumentNumber?.patchValue(null);
    this.form.controls.referentDocumentNumber?.updateValueAndValidity();
    this.form.controls.referentDocumentDT?.patchValue(null);
    this.form.controls.referentDocumentDT?.updateValueAndValidity();

    this._setValidators();
  }

  private _isReferentDocumentNumberValid(): boolean {
    const regex = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{8}-\d{2}$/;
    return regex.test(this.form.controls.referentDocumentNumber?.value!);
  }

  ngOnDestroy(): void {
    this._fiskalniService.setFormSaveClicked = false;
    this._subs.unsubscribe();
  }
}
