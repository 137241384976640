<!-- Template za kreiranje izlazne fakture step 4(content) -->
<ng-template #izlaznaFakturaKreiranjeStep4>
  <p>Ovde mozemo dodati stavke na fakturu.</p>

  <p>
    Prilikom unosa naziva ili sifre stavke, automatski se vrsi pretraga i
    pojavljuje se prozor sa rezultatima pretrage.
  </p>
  <p>
    Ukoliko uneta stavka ne postoji, mozete uneti novu u jezicku koji ce se
    otvoriti.
  </p>
</ng-template>

<div class="p-d-flex p-flex-column p-mt-3" [formGroup]="racunForm" #parent>
  <!-- Tabela stavki dokumenta -->
  <p-table
    [expandableRows]="shouldBeExpandable"
    [value]="stavkeRacuna.controls"
    styleClass="p-datatable-striped p-datatable-gridlines"
    [(selection)]="selectedStavke"
    dataKey="redniBroj"
  >
    <!-- START HEADER -->
    <ng-template pTemplate="header">
      <!-- header columns -->
      <tr class="columns">
        <th style="width: 1vw" *ngIf="shouldBeExpandable"></th>
        <!-- <th style="width: 1vw">R.br.</th> -->
        <!-- <th style="width: 7vw">Šifre</th> -->
        <th style="width: 22vw">Predmet stavke</th>
        <th style="width: 7vw" class="p-text-right">Količina i JM</th>
        <th style="width: 7vw" class="p-text-right">Cena po JM</th>
        <th style="width: 7vw" class="p-text-right" *ngIf="shouldDisplayRabat">
          Popust
          {{ prikazRabatKaoProcenat ? '%' : racunForm.get('valutaText').value ?? 'RSD' }}
        </th>
        <th style="width: 5vw" class="p-text-right" *ngIf="shouldDisplayPDV">
          Iznos bez PDV
        </th>
        <th style="width: 5vw" class="p-text-right" *ngIf="shouldDisplayPDV">
          PDV
        </th>
        <th style="width: 5vw" *ngIf="shouldDisplayRazlogPdvIzuzeca">
          Osnov za oslobođenje/izuzeće od PDV-a
        </th>
        <th style="width: 5vw" *ngIf="shouldDisplayIdentifikator">
          Identifikator klasifikacije stavke
        </th>
        <!-- <th style="width: 5vw" *ngIf="shouldDisplayPDV">PDV</th>
                                                                                        <th style="width: 5vw">
                                                                                          {{ shouldDisplayPDV ? 'Iznos sa PDV' : 'Iznos' }}
                                                                                        </th> -->
        <th style="width: 1vw"></th>
      </tr>
    </ng-template>

    <!-- START BODY -->

    <!-- Rows -->
    <ng-template
      pTemplate="body"
      let-stavka
      let-rowIndex="rowIndex"
      let-expanded="expanded"
    >
      <ng-container formArrayName="stavkeDto">
        <tr [formGroupName]="rowIndex">
          <td style="vertical-align: middle" *ngIf="shouldBeExpandable">
            <button
              type="button"
              pButton
              pRipple
              [pRowToggler]="stavka"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            ></button>
          </td>
          <!-- redni broj -->
          <!-- <td style="vertical-align: middle" class="p-text-center">
                                                                                                      <div class="celija">
                                                                                                        {{ stavka.get('redniBroj').value }}
                                                                                                      </div>
                                                                                                    </td> -->
          <!-- naziv predmet stavke -->
          <td>
            <div class="inputFullWidth celija">
              <kodit-predmet-stavke-racuna-input
                joyrideStep="izlaznaFakturaKreiranjeStep4"
                [stepContent]="izlaznaFakturaKreiranjeStep4"
                title="Stavke"
                [predmetStavkeFormGroup]="
                  stavkeRacuna.at(rowIndex).get('predmetStavkeDto')
                "
                (onNazivChange)="updateRezultate($event, rowIndex)"
                [ngClass]="
                  shouldDisableEditIznosa && rowIndex < brojPocetnihStavki
                    ? 'p-disabled inputFullWidth celija'
                    : ''
                "
              ></kodit-predmet-stavke-racuna-input>

              <div
                class="p-mt-2"
                *ngIf="
                  stavka.get('predmetStavkeDto.tipPredmeta').value ===
                  tipPredmetaRoba
                "
              >
                <kodit-magacin-dropdown
                  [magacinCtrl]="stavka.get('magacinId')"
                  [ngClass]="
                    shouldDisableMagacinDropdown &&
                    rowIndex < brojPocetnihStavki
                      ? 'p-disabled inputFullWidth celija'
                      : ''
                  "
                ></kodit-magacin-dropdown>
              </div>

              <div class="p-mt-2">
                <button
                  *ngIf="
                    showOpiseStavki.indexOf(rowIndex) === -1 &&
                    !stavkeRacuna.at(rowIndex).get('opis').value
                  "
                  pButton
                  pRipple
                  type="button"
                  class="p-button-link p-pl-0 dodaj-opis-btn"
                  (click)="addOpisStavke(rowIndex)"
                  label="Dodaj opis"
                ></button>
                <textarea
                  *ngIf="
                    showOpiseStavki.indexOf(rowIndex) > -1 ||
                    stavkeRacuna.at(rowIndex).get('opis').value
                  "
                  [rows]="1"
                  [cols]="30"
                  pInputTextarea
                  autoResize="autoResize"
                  placeholder="Opis predmeta stavke (opciono)"
                  [placeholder]="
                    jeAvansniRacun ? 'Opis' : 'Opis predmeta stavke (opciono)'
                  "
                  [formControl]="stavkeRacuna.at(rowIndex).get('opis')"
                ></textarea>
              </div>
              <div
                class="p-field"
                [ngClass]="'inputFullWidth celija'"
                *ngIf="!jeAvansniRacun"
              >
                <hr />
                <kodit-text-input
                  class="p-3 flex"
                  [formCtrl]="stavka.get('sifraKupca')"
                  ctrlPlaceholder="Identifikator artikla kod kupca"
                ></kodit-text-input>
              </div>
            </div>
          </td>
          <!-- kol. -->
          <td class="p-text-right">
            <div
              [ngClass]="
                shouldDisableKolicina && rowIndex < brojPocetnihStavki
                  ? 'p-disabled inputFullWidth p-d-flex p-ai-center p-flex-column p-flex-md-row gap-12 celija'
                  : 'inputFullWidth p-d-flex p-ai-center p-flex-column p-flex-md-row gap-12 celija'
              "
            >
              <!-- KOL -->
              <div>
                <kodit-number-input
                  [formCtrl]="stavka.get('kolicina')"
                  (onInputLeft)="handleKolicinaUplade(rowIndex)"
                ></kodit-number-input>
              </div>
              <!-- JM -->
              <!--                <kodit-jedinica-mere-dropdown-->
              <!--                  [jedinicaMereGroup]="stavka.get('jedinicaMere')"-->
              <!--                  ctrlPlaceholder="&#45;&#45;"-->
              <!--                >-->
              <!--                </kodit-jedinica-mere-dropdown>-->
              <div>
                {{ stavka.get('jedinicaMere').value.naziv }}
              </div>
            </div>
          </td>
          <!-- Cena za ulaznu fakturu -->
          <td class="p-text-right">
            <!-- Prodajna cena bez pdv -->
            <div
              *ngIf="!shouldDisplayDodatnaPolja"
              [ngClass]="
                shouldDisableCena && rowIndex < brojPocetnihStavki
                  ? 'p-disabled inputFullWidth celija'
                  : 'inputFullWidth celija'
              "
            >
              <kodit-number-input
                [formCtrl]="stavka.get('prodajnaCenaBezPdv')"
                (onInputLeft)="handleProdajnaCenaChanged(rowIndex)"
              ></kodit-number-input>
            </div>
            <!-- Nabavna cena bez pdv -->
            <div
              [ngClass]="
                shouldDisableCena && rowIndex < brojPocetnihStavki
                  ? 'p-disabled inputFullWidth celija'
                  : 'inputFullWidth celija'
              "
              *ngIf="shouldDisplayDodatnaPolja"
            >
              <kodit-number-input
                [formCtrl]="stavka.get('nabavnaCenaBezPdv')"
                (onInputLeft)="handleNabavnaCenaChanged(rowIndex)"
              ></kodit-number-input>
            </div>

            <div
              class="p-d-flex p-flex-column inputFullWidth celija p-mt-3"
              *ngIf="
                stavka.get('predmetStavkeDto.tipPredmeta').value ==
                  tipPredmetaRoba && shouldDisplayDodatnaPolja
              "
            >
              <kodit-field
                fieldLabel="Prodajna cena bez PDV"
                fieldLabelFor="pravno-lice"
              >
                <kodit-number-input
                  [formCtrl]="stavka.get('prodajnaCenaBezPdv')"
                  (onInputLeft)="handleProdajnaCenaChanged(rowIndex)"
                ></kodit-number-input>
              </kodit-field>

              <!-- Marza -->
              <div
                class="p-field p-mt-2"
                [ngClass]="
                  shouldDisableCena && rowIndex < brojPocetnihStavki
                    ? 'p-disabled inputFullWidth celija'
                    : 'inputFullWidth celija'
                "
              >
                <label kodit-form-label>Marža izražena u %</label>
                <kodit-number-input
                  [formCtrl]="stavka.get('predmetStavkeDto.marza')"
                  (onInputLeft)="handleMarzaCenaChanged(rowIndex)"
                ></kodit-number-input>
              </div>

              <!-- Razlika u ceni -->
              <div
                class="p-field p-mt-2"
                [ngClass]="
                  shouldDisableCena && rowIndex < brojPocetnihStavki
                    ? 'p-disabled inputFullWidth celija'
                    : 'inputFullWidth celija'
                "
              >
                <label kodit-form-label>Razlika u ceni</label>
                <kodit-number-input
                  [formCtrl]="stavka.get('predmetStavkeDto.razlikaUCeni')"
                  (onInputLeft)="handleRazlikaUCeniChanged(rowIndex)"
                ></kodit-number-input>
              </div>

              <!-- Prodajna sa PDV -->
              <div
                class="p-field p-mt-2"
                [ngClass]="
                  shouldDisableCena && rowIndex < brojPocetnihStavki
                    ? 'p-disabled inputFullWidth celija'
                    : 'inputFullWidth celija'
                "
              >
                <label kodit-form-label>Prodajna cena sa PDV</label>
                <kodit-number-input
                  [isDisabled]="true"
                  [formCtrl]="stavka.get('predmetStavkeDto.prodajnaCenaSaPdv')"
                  (onInputLeft)="updateModel(rowIndex)"
                ></kodit-number-input>
              </div>
            </div>
          </td>

          <!-- Cena za ostale racune -->

          <!--           <td class="p-text-right" *ngIf="!jeUlaznaFaktura">
            <div
              [ngClass]="
                shouldDisableCena
                  ? 'p-disabled inputFullWidth celija'
                  : 'inputFullWidth celija'
              "
            >
              <kodit-number-input
                [minFractionDigits]="4"
                [maxFractionDigits]="4"
                [formCtrl]="stavka.get('prodajnaCenaBezPdv')"
                (onInputLeft)="updateModel(rowIndex)"
              ></kodit-number-input>
            </div>
          </td>
  -->

          <!-- popust / rabat -->
          <td class="p-text-right" *ngIf="shouldDisplayRabat">
            <div
              [ngClass]="
                shouldDisableEditIznosa && rowIndex < brojPocetnihStavki
                  ? 'p-disabled inputFullWidth celija'
                  : 'inputFullWidth celija'
              "
            >
              <kodit-number-input
                [formCtrl]="stavka.get('rabat')"
                (onInputLeft)="updateModel(rowIndex)"
              ></kodit-number-input>
            </div>
          </td>
          <!-- poreska osnovica -->
          <td *ngIf="shouldDisplayPDV" class="p-text-right">
            <div ngClass="celija">
              {{ stavka.get('poreskaOsnovica').value | currencyNumber: null }}
            </div>
          </td>
          <!-- stopa pdv -->
          <td *ngIf="shouldDisplayPDV" class="p-text-right">
            <div class="inputFullWidth celija">
              <kodit-pdv-stopa-dropdown
                [pdvStopaCtrl]="stavka.get('stopaPDV')"
                (onItemChange)="handlePdvStopaChange(rowIndex)"
                [ngClass]="
                  shouldDisableEditIznosa && rowIndex < brojPocetnihStavki
                    ? 'p-disabled'
                    : ''
                "
                (onDefaultValuteSet)="updatePdvIzuzeceDisplay()"
              ></kodit-pdv-stopa-dropdown>
            </div>
          </td>
          <!-- razlog pdv izuzeca ako nismo u PDV sistemu -->
          <td style="vertical-align: top" *ngIf="shouldDisplayRazlogPdvIzuzeca">
            <div
              class="inputFullWidth celija"
              *ngIf="stavka.value.stopaPDV === 0"
            >
              <kodit-pdv-kategorija-dropdown
                [pdvKategorijaCtrl]="stavka.get('pdvKategorija')"
                (onItemChange)="
                  handleOznakaPdvKategorijeChanged($event, rowIndex)
                "
              ></kodit-pdv-kategorija-dropdown>
              <div class="p-mt-2">
                <kodit-pdv-razlog-izuzeca-dropdown
                  [isVisible]="true"
                  [pdvRazlogIzuzecaCtrl]="stavka.get('razlogPdvIzuzecaId')"
                  (onItemChange)="
                    handleRazlogPdvIzuzecaChanged($event, rowIndex)
                  "
                  [oznakaPdvKategorije]="stavka.get('pdvKategorija').value"
                >
                </kodit-pdv-razlog-izuzeca-dropdown>
              </div>
            </div>
            <div
              class="celija p-text-center"
              *ngIf="stavka.value.stopaPDV !== 0"
            >
              --
            </div>
          </td>
          <td *ngIf="shouldDisplayIdentifikator" class="p-text-right">
            <div
              class="inputFullWidth celija"
              *ngIf="
                stavka.value.stopaPDV === 10 || stavka.value.stopaPDV === 20
              "
            >
              <kodit-identifikator-stavke-dropdown
                [identifikatorCtrl]="stavka.get('identifikator')"
              ></kodit-identifikator-stavke-dropdown>
            </div>
            <div
              class="celija p-text-center"
              *ngIf="
                stavka.value.stopaPDV !== 10 && stavka.value.stopaPDV !== 20
              "
            >
              --
            </div>
          </td>
          <!-- pdv -->
          <!-- <td *ngIf="shouldDisplayPDV">
                                                                                                                <div class="celija">
                                                                                                                  {{ stavka.get('pdv').value | decimalNumber }}
                                                                                                                </div>
                                                                                                              </td> -->
          <!--  ukupan iznos stavke -->
          <!-- <td>
                                                                                                                <div class="celija">
                                                                                                                  {{ stavka.get('iznos').value | decimalNumber }}
                                                                                                                </div>
                                                                                                              </td> -->
          <td style="vertical-align: middle" class="p-text-center">
            <div class="celija">
              <!-- <p-tableCheckbox [value]="stavka.value"></p-tableCheckbox> -->
              <button
                *ngIf="
                  !shouldDisableEditIznosa ||
                  (jeKonvertovanje && rowIndex >= brojPocetnihStavki)
                "
                pButton
                pRipple
                type="button"
                icon="far fa-trash-alt"
                class="p-button-text p-button-danger"
                pTooltip="Ukloni stavku sa računa"
                (click)="deleteStavka(rowIndex)"
              ></button>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <!-- Expanded rows  -->
    <!-- Sirovine za stavku racuna -->
    <ng-template
      class=""
      pTemplate="rowexpansion"
      let-stavka
      let-stavkaIndex="rowIndex"
    >
      <tr
        *ngFor="
          let materijal of stavka.controls.materijali.controls;
          let materijalIndex = index
        "
      >
        <!-- prve 2 kolone preskacemo -->
        <td colspan="1"></td>
        <!-- predmet stavke (materijal) i magacin -->
        <td colspan="1">
          <div class="inputFullWidth celija">
            <kodit-predmet-stavke-racuna-input
              [predmetStavkeFormGroup]="materijal.get('predmetStavkeDto')"
              ngClass="p-disabled inputFullWidth celija"
            ></kodit-predmet-stavke-racuna-input>

            <div class="p-mt-2">
              <kodit-magacin-dropdown
                [magacinCtrl]="materijal.get('magacinId')"
              ></kodit-magacin-dropdown>
            </div>
          </div>
        </td>
        <!-- kolicina i jm -->
        <td colspan="1">
          <div
            class="inputFullWidth p-d-flex p-flex-column p-flex-md-row gap-12 celija p-ai-center p-disabled"
          >
            <!-- KOL -->
            <div>
              <kodit-number-input
                [formCtrl]="materijal.get('kolicina')"
                (onInputLeft)="updateModel(materijalIndex)"
              ></kodit-number-input>
            </div>
            <!-- JM -->
            <div>
              {{ materijal.get('jedinicaMere').value.naziv }}
            </div>
          </div>
        </td>
        <!-- cena po jm -->
        <td colspan="1">
          <div class="p-disabled celija inputFullWidth">
            <kodit-number-input
              [formCtrl]="materijal.get('nabavnaCenaBezPdv')"
              (onInputLeft)="updateModel(materijalIndex)"
            ></kodit-number-input>
          </div>
        </td>
        <!-- popust -->
        <td *ngIf="shouldDisplayRabat" colspan="1">
          <div class="p-text-right p-disabled celija inputFullWidth">
            <kodit-number-input
              [formCtrl]="materijal.get('rabat')"
              (onInputLeft)="updateModel(materijalIndex)"
            ></kodit-number-input>
          </div>
        </td>
        <!-- poreska osnovica -->
        <td colspan="1" *ngIf="shouldDisplayPDV">
          <div class="p-text-right p-disabled celija">
            {{ materijal.get('poreskaOsnovica').value | currencyNumber: null }}
          </div>
        </td>
        <!-- pdv -->
        <td colspan="1" *ngIf="shouldDisplayPDV">
          <div class="p-text-right p-disabled celija">
            {{ materijal.get('stopaPDV').value }} %
          </div>
        </td>
        <!-- button za brisanje -->
        <!-- <td style="vertical-align: middle" class="p-text-center" colspan="1">
                                                  <div class="celija">
                                                    <button
                                                      pButton
                                                      pRipple
                                                      type="button"
                                                      icon="fa-regular fa-xmark"
                                                      class="p-button-text p-button-danger"
                                                      pTooltip="Ukloni materijal sa stavke"
                                                      (click)="deleteMaterijalStavke(materijalIndex, stavkaIndex)"
                                                    ></button>
                                                  </div>
                                                </td> -->
      </tr>
    </ng-template>
  </p-table>

  <!-- Button za dodavanje stavke -->
  <div class="p-d-flex p-mt-3">
    <button
      *ngIf="!shouldHideAddEmptyStavka"
      pButton
      pRipple
      type="button"
      icon="fas fa-plus"
      class="p-button-outlined"
      (click)="addEmptyStavka()"
      label="Nova stavka"
      pTooltip="Dodaj novu stavku na račun"
    ></button>
  </div>

  <!-- Tabela ukupnog iznosa -->
  <div class="p-mt-5" *ngIf="shouldDisplayUkupanIznos">
    <kodit-ukupan-iznos-table></kodit-ukupan-iznos-table>
  </div>

  <!-- Rezultat pretrage -->
  <div
    class="layout-sidebar-right rezultati"
    [ngClass]="{ 'layout-sidebar-right-active': shouldDisplayRezultate }"
  >
    <kodit-rezultat-pretrage-predmeta-stavke
      *ngIf="shouldDisplayRezultate"
      [searchValue]="rezultatiSearchValue"
      [excludeTipovePredmeta]="excludeTipovePredmeta"
      (onPredmetStavkeAdd)="handleAddPredmetStavke($event)"
      [tipRacuna]="tipRacuna"
    ></kodit-rezultat-pretrage-predmeta-stavke>
  </div>
</div>
